
body {
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif !important;
}

.App {
  min-height: 100vh;
  background-size: 100% 100%;
  background-attachment: fixed;
  max-width: 768px;
  margin: auto;
  width: 100%;
}

.page-center {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  margin: auto;
}

.location-marker {
  width: 80px;
}

.font-roboto {
  font-family: 'Roboto', sans-serif !important;
}

.welcome {
  font-size: 1.5em;
}

.network-status {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px 15px;
  color: orange;
  z-index: 100;

  &.active {
    color: #90d9ff;
  }
}

.smollan-logo {
  max-width: 200px;
}

.upload {
  position: relative;

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    font-size: 1.5em;
  }
}

.field-required-hide {
  height: 1px;
  padding: 0px;
  border: unset;
  position: relative;
  top: -10px;
  z-index: -1;
}

.pointer{
  cursor: pointer;
}

.tbody-overlap {
  display: block;
  height: 65px;
  transition: all .3s ease-in-out;
  @include media-breakpoint-up(lg){
    height: 40px;
  }
  tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }

  &.active {
    height: auto;
  }
  &:not(.active){
    overflow: hidden;
  }
}

#version{
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  font-size: 10px;
  padding: 2px 5px;
  color: #f2f2f2;
}

.attendance_page {
  #take_photo{
    position: relative;
    label {
      flex-direction: column;
      &::before{
        content: "";
        width: 100%;
        height: 200px;
        margin: 10px auto;
        border: 4px dotted #f2f2f2;
        border-radius: 6px;
      }
      &::after {
        content: "CHỤP ẢNH";
        position: absolute;
        top: calc( 50% - 20px );
        left: 50%;
        color: #f2f2f2;
        transform: translate(-50%, -50%);
      }
    }
    img ~ label {
      &::before, &::after {
        display: none;
      }
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.ui.labeled.input>.label {
  max-width: 70%;
}