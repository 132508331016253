.group_question {
  $height: 40px;

  ul {
    padding-left: 15px;
    margin-bottom: 0;

    li {
      width: 100%;
      margin-bottom: 5px;

      button {
        display: block;
        align-items: center;
        border: unset;
        padding-left: 20px;
        background-color: #fff;
        width: 100%;
        height: $height;
        text-align: left;
        border-bottom: 1px solid #f2f2f2;
        border-radius: unset;
        @extend .shadow-sm;

        &.active, &:hover {
          background-color: #005cbf;
          color: #fff;
        }
      }
    }
  }

  & > ul {
    padding-left: 0;
  }
}

.field-repeat {
  .rows {
    display: flex;
    align-items: center;
    & > div {
      &:nth-child(1) {
        flex: 0 0 calc(100% - 75px);
      }
      &:nth-child(2) {
        flex: 0 0 30px;
      }
    }
  }
}